import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Container from '~/components/container';
import '~/styles/layout.css';

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/bgx7fdn.css" />
    </Helmet>
    <div className="flex flex-col h-full">
      <Header />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
