import React from 'react';

const Footer = () => (
  <footer className="mt-12">
    <div className="h-12">
      <div css={{ width: '9rem' }} className="inline-block h-full bg-x-teal" />
      <div className="inline-block w-12 h-full bg-x-darkred" />
    </div>
    <div className="bg-x-darkblue h-8" />
  </footer>
);
export default Footer;
