import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Container = ({ className, children }) => (
  <div
    className={classNames(
      className,
      'max-w-4xl',
      'container',
      'mx-auto',
      'px-4 md:px-8'
    )}
  >
    {children}
  </div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Container;
