import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import Container from '~/components/container';

const Header = () => (
  <header className="bg-x-darkblue">
    <Container>
      <div className="text-right">
        <Link to="/profiles">
          <span className="inline-block p-2 text-x-pink eaves text-xl">
            Coach Profiles
          </span>
        </Link>
      </div>
    </Container>
  </header>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
